<!-- 考生留言 -->
<template>
    <div class="examineeMsg">
        <div class="msg">
            考生留言
        </div>
        <div class="" style="flex: 1;">
            <el-form ref="form" :model="form" :rules="rules" id="form-1" class="form" inline label-width="120px">
                <el-form-item label="留言" prop="explain">
                    <el-input  :rows="8" type="textarea" v-model="form.explain" class="textArea-width" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="评分" >
                    <el-select v-model="form.score" placeholder="请选择状态" :clearable="true">
                        <el-option
                          v-for="item in form.scoreOpt"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                </el-form-item>
            </el-form>   
            <el-divider></el-divider>
            <!-- 底部 提交与取消 -->
            <div slot="footer" class="dialog-footer">
                <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
                <el-button class="btn" @click="resetForm('form')">取 消</el-button>
            </div>
        </div>
        <el-divider></el-divider>
        <div class="buttom">
            <div class="">
                &copy2015 深圳书画学院 考生考级报名系统 All rights reserved
            </div>
            <div class="">
                服务热线：0755-8883 0206
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                   explain:"" ,//留言
                   score:"1",//评分
                   scoreOpt:[
                       {label: "好评", value: "1"},
                       {label: "中评", value: "2"},
                       {label: "差评", value: "3"},
                   ],
                   name:""
                },
                rules:{
                    explain:[
                         { required: true, message: '留言不能为空', trigger: 'blur' },
                    ]
                },
                bool:false,
                info:{}
            }
        },
        created() {
            this.info=JSON.parse(localStorage.getItem('info'))
            this.form.name=this.info.username
            
            // console.log(this.form.name)
        },
        methods:{
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        if(this.bool){
                            return
                        }   
                        this.bool=true
                        this.$request({url: '/api/studentexam/messageAdd', method: 'POST', data: {
							content: this.form.explain,
							type: this.form.score,
							uid: localStorage.getItem('token'),
							student_id: '',
                            name:this.form.name
						}}).then(res => {
							if(res.code == 1){
								this.$router.replace({
								    path: "/msgList",
								})
								this.$message({
								  message: '留言成功',
								  type: 'success'
								});
							}
						}).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
            this.$router.replace({
                path: "/msgList",
            })
                this.$refs[form].resetFields();
            },
        }
    }
</script>

<style scoped="scoped">
    .examineeMsg{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .msg{
        padding: 25px;
        font-size: 16px;
        font-weight: bold;
    }
    /* 表单部分 */
    .el-form {
        width: 80%;
        margin:0px auto;
        padding: 30px 0px;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    

    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        margin-top: 50px;
    }
    
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
    /* 底部 */
    .buttom{
        height: 30px;
        line-height: 30px;
        padding: 0px 25px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
</style>
